import { googleAnalyticsId } from './env'

export function gtag(x: string, y: string, z: Record<string, any>) {
  const _window = window as any
  _window.gtag(x, y, z)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  gtag('config', googleAnalyticsId, {
    page_path: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value
}: {
  action: string
  category?: string
  label?: string
  value?: string
}) => {
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}
