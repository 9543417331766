import { useEffect } from 'react'
import { useRouter } from 'next/router'
import segment from 'lib/segment'

export function usePageViewAnalytics() {
  const router = useRouter()
  
  useEffect(() => {
    const handleRouteChange = () => {
      segment.page()
    }
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)
    
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}