import Script from 'next/script'
import Router from 'next/router'
import { pageview } from 'lib/gtag'
import { googleAnalyticsId } from 'lib/env'
import type { AppProps } from 'next/app'
import { usePageProgress } from 'ui/hooks/page-progress'
import { usePageViewAnalytics } from 'lib/analytics'

import 'styles/globals.css'
import 'trix/dist/trix.css'

Router.events.on('routeChangeComplete', (url) => pageview(url))

function App({ Component, pageProps }: AppProps) {
  usePageProgress()
  usePageViewAnalytics()

  return (
    <>
      <Script src='https://cdn.blueconic.net/barstool.js' strategy='afterInteractive' />
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-E32L1FWPGL'
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsId}');
      `}</Script>
      <Component {...pageProps} />
    </>
  )
}

export default App
