export const basePath = process.env.NEXT_PUBLIC_BASE_PATH ?? ''

export const jobsApi = process.env.NEXT_PUBLIC_JOBS_API ?? 'https://jobs-api.origin.barstool.dev'

export const jobsSite = process.env.NEXT_PUBLIC_JOBS_SITE ?? 'barstooljobs'

export const googleAnalyticsId = process.env.NEXT_PUBLIC_GA_ID ?? 'G-XXXXX'

export const siteName = (() => {
  switch (jobsSite) {
    case 'worklikeagirl':
      return 'Work Like A Girl'
    default:
      return 'Barstool Jobs'
  }
})()
